import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      courseCredential: {},
      isDownloadLoading: false
    };
  },
  computed: {
    ...mapGetters(["certificateDetail", "allConfig"]),
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
    isShareAward() {
      return this.$route.name === "awards-share";
    },
    courseId() {
      return this.$route.params.id;
    },
    key() {
      return this.isShareAward ? this.$route.params.id : this.$route.query.key;
    }
  },
  methods: {
    downloadCertificate(includeCredentials = false) {
      let params = {
        id: this.courseId,
        includeMicroCredentials: includeCredentials
      };
      this.isDownloadLoading = true;
      this.$store
        .dispatch("downloadCertificate", params)
        .then(() => {
          this.isDownloadLoading = false;
        })
        .catch(() => {
          this.isDownloadLoading = false;
        });
    },
    downloadCredential() {
      this.isDownloadLoading = true;
      this.$store
        .dispatch("downloadCredential", { key: this.key })
        .then(() => {
          this.isDownloadLoading = false;
        })
        .catch(() => {
          this.isDownloadLoading = false;
        });
    },
    getCertificateDetail(detailedCredentials = false) {
      this.isLoading = true;
      let dispatchMethod,
        params = {};
      if (this.isAuthenticated && !this.isShareAward) {
        dispatchMethod = "getCertificateDetail";
        params = { id: this.courseId };
      } else {
        dispatchMethod = "getPublicCertificateDetail";
        params = {
          id: this.$route.params.id,
          detailedCredentials: detailedCredentials
        };
      }
      this.$store.dispatch(dispatchMethod, params).then(() => {
        this.isLoading = false;
      });
    },
    getCredentialDetail() {
      let URL = `${this.allConfig.baseURL}/v2/certificates/modules/${this.key}/public/`,
        headers = {};
      if (this.isAuthenticated && !this.isShareAward) {
        URL = `${this.allConfig.baseURL}/v2/certificates/modules/${this.credentialId}`;
        headers = {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`
          }
        };
      }
      this.isLoading = true;

      axios
        .get(URL, headers)
        .then(res => {
          this.isAuthenticated && !this.isShareAward
            ? (this.courseCredential = res.data.data)
            : (this.courseCredential = res.data);
          if (this.isAuthenticated) {
            const breadcrumbItems = [
              {
                icon: null,
                text: this.certificateDetail.display_name
                  ? this.certificateDetail.display_name
                  : this.courseCredential.course_title,
                to: `/coursewares/${
                  this.certificateDetail.course_key_string
                    ? this.certificateDetail.course_key_string
                    : this.courseCredential.course_id
                }`
              },
              {
                icon: null,
                text: this.courseCredential?.credential?.title,
                active: true
              }
            ];
            this.setBreadCrumbs(breadcrumbItems);
          }

          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
          this.showError = true;
          this.$store.commit("SET_ERRORS", err.response.data);
        });
    },
    navigateTo(path) {
      this.$router.push({ path: path }).catch(() => {});
    },
    setBreadCrumbs(breadcrumbItems) {
      if (this.$route.query.from === "discovery") {
        this.discoveryBreadcrumbs.push(...breadcrumbItems);
      } else if (this.$route.query.from === "course-detail") {
        this.courseDetailBreadcrumbs.push(...breadcrumbItems);
      } else {
        this.breadcrumbs.push(...breadcrumbItems);
      }
    }
  }
};
